import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Contrast } from '../theme/contrast.theme';
import { Dark } from '../theme/dark.theme';
import { Light } from '../theme/light.theme';
import { Login } from '../theme/login.theme';
import { Theme } from '../theme/theme.interface';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
    private active: Theme = Light;
    private availableThemes: Theme[] = [Light, Dark, Login, Contrast];

    get isLoginPage(): boolean { return this.router.url === '/login'; }

    constructor(private router: Router) { }

    public init(): void {
        if (this.isLoginPage) {
            this.setLoginTheme();
        } else {
            if (sessionStorage['theme']) {
                switch (sessionStorage['theme']) {
                    case 'Dark':
                        this.setDarkTheme();
                        break;
                    case 'Contrast':
                        this.setContrastTheme();
                        break;
                    default:
                        this.setLightTheme();
                }
            } else {
                this.setLightTheme();
            }
        }
    }

    public getAvailableThemes(): Theme[] {
        return this.availableThemes;
    }

    public getActiveTheme(): Theme {
        return this.active;
    }

    public isTheme(themeName: string): boolean {
        return this.active.name === themeName;
    }

    public setDarkTheme(): void {
        this.setActiveTheme(Dark);
        this.rememberTheme();
    }

    public isDarkTheme(): boolean {
        return this.active.name === Dark.name;
    }

    public setLightTheme(): void {
        this.setActiveTheme(Light);
        this.rememberTheme();
    }

    public isLightTheme(): boolean {
        return this.active.name === Light.name;
    }

    public setContrastTheme(): void {
        this.setActiveTheme(Contrast);
        this.rememberTheme();
    }

    public isContrastTheme(): boolean {
        return this.active.name === Contrast.name;
    }

    public setLoginTheme(): void {
        this.setActiveTheme(Login);
    }

    public isLoginTheme(): boolean {
        return this.active.name === Login.name;
    }

    public setActiveTheme(theme: Theme): void {
        this.active = theme;

        Object.keys(this.active.properties).forEach(property => {
            document.documentElement.style.setProperty(
                property,
                this.active.properties[property]
            );
        });

        this.rememberTheme();
    }

    public setDefaultIfLogIn(): void {
        if (this.active === Login) {
            this.setLightTheme();
        }
    }

    private rememberTheme(): void {
        sessionStorage['theme'] = this.active.name;
    }
}
