import { Theme } from './theme.interface';

export const Dark: Theme = {
    name: 'Dark',
    properties: {
      '--foreground-default': 'white',
      '--foreground-secondary': '#F5F5F5',
      '--foreground-tertiary': 'white',
      '--foreground-quaternary': 'white',

      '--background-default': '#797C80',
      '--background-secondary': '#41474D',
      '--background-tertiary': '#C2C2C2',
      '--background-quaternary': '#797C80',

      '--button-primary-background': '#545454',
      '--button-primary-foreground': 'white',
      '--button-primary-background-disabled': '#5E5E5E',
      '--button-primary-foreground-disabled': 'white',
      '--button-secondary-background': '#707070',
      '--button-secondary-foreground': 'white',
      '--button-secondary-background-disabled': '#707070',
      '--button-secondary-foreground-disabled': 'white',
      '--button-accent-background': '#e83e8c',
      '--button-accent-foreground': 'white',
      '--button-danger-background': 'red',
      '--button-danger-foreground': 'white',

      '--shadow': '0 1px 3px 0 rgba(8, 9, 10, 0.5)',
      '--error-icon': '#797C80',

      '--or-background': '#272829',
      '--or-foreground': '#E9EEF5',
      '--spinner': 'invert(99%) sepia(80%) saturate(0%) hue-rotate(291deg) brightness(115%) contrast(88%)',
      '--loading-background': '#797C80',
      '--quill-toolbar-svg': 'invert(99%) sepia(31%) saturate(425%) hue-rotate(247deg) brightness(109%) contrast(80%);',
      '--chart-background': 'white',
      '--progress-color': '#C2C2C2',
      '--dialog-background': '#797C80',
      '--mca-background': '#272829',
      '--mca-foreground': '#E9EEF5',

      '--preview-one': '#F5F5F5',
      '--preview-two': 'black'
    },
    canSelect: true
};
