import { Theme } from './theme.interface';

export const Contrast: Theme = {
    name: 'Contrast',
    properties: {
      '--foreground-default': 'black',
      '--foreground-secondary': 'black',
      '--foreground-tertiary': 'black',
      '--foreground-quaternary': 'black',

      '--background-default': '#FFFB09',
      '--background-secondary': '#FFFB09',
      '--background-tertiary': '#FFFB09',
      '--background-quaternary': '#FFFB09',

      '--button-primary-background': '#E8CD02',
      '--button-primary-foreground': 'black',
      '--button-primary-background-disabled': '#FFC803',
      '--button-primary-foreground-disabled': 'black',
      '--button-secondary-background': '#E8CD02',
      '--button-secondary-foreground': 'black',
      '--button-secondary-background-disabled': '#FFC803',
      '--button-secondary-foreground-disabled': 'black',
      '--button-accent-background': '#e83e8c',
      '--button-accent-foreground': 'white',
      '--button-danger-background': 'red',
      '--button-danger-foreground': 'white',

      '--shadow': '0 1px 3px 0 rgba(8, 9, 10, 0.5)',
      '--error-icon': 'black',

      '--or-background': '#FFFB09',
      '--or-foreground': 'black',
      '--spinner': 'invert(0%) sepia(0%) saturate(0%) hue-rotate(308deg) brightness(98%) contrast(101%)',
      '--loading-background': '#FFFB09',
      '--quill-toolbar-svg': 'invert(0%) sepia(0%) saturate(0%) hue-rotate(308deg) brightness(98%) contrast(101%)',
      '--chart-background': 'white',
      '--progress-color': 'black',
      '--dialog-background': '#FFFB09',
      '--mca-background': '#FFFB09',
      '--mca-foreground': 'black',

      '--preview-one': '#FFFB09',
      '--preview-two': 'black'
    },
    canSelect: true
};
